import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import axios from 'axios';
import moment from 'moment';
import VueClipboard from 'vue-clipboard2';
import VueFormWizard from 'vue-form-wizard';
import VueSkeletonLoader from 'skeleton-loader-vue';
import router from './router';
import store from './store';
import App from './App.vue';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlaneDeparture,
  faPlaneArrival,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import JsonCSV from 'vue-json-csv';
import * as Vue2Leaflet from 'vue2-leaflet';
import { Chrome } from 'vue-color';

// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';

Vue.use(VueFormWizard);

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.component('vue-skeleton-loader', VueSkeletonLoader);
Vue.component('chrome-picker', Chrome);
Vue.prototype.moment = moment;

// Vue-json-csv
Vue.component('exportCsv', JsonCSV);

// Composition API
Vue.use(VueCompositionAPI);

//Leaflet street map
Vue.use(Vue2Leaflet);

import 'leaflet/dist/leaflet.css';

// import feather font icons for form wizard
require('@core/assets/fonts/feather/iconfont.css');

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

require('@/store/subscriber');

 
const baseUrl = 'https://api.demo.beontrips.hu/v1'; //LIVE SERVER
// const baseUrl = 'https://api.beontrips.oneclicksales.xyz/v1'; // SALES SERVER
// const baseUrl = 'https://api.demo.beontrips.hu/v1'; // DEMO SERVER
// const baseUrl = 'http://localhost:4000/v1'; // LOCALHOST

axios.defaults.baseURL = baseUrl;
/**
 * IF ADMIN PANEL BUILD FOR SPECIFIC WHITELABEL THEN,
 * JUST PASS THE WLCODE ID WITHOUT TERNARY CONDITION
 * OF WLCODE AND SIGNINASADMIN LOCAL STORAGE VARIABLES.
 * WE USED IT FOR MAIN BEONTRIPS ADMIN PANEL TO LOG IN
 * AS WL FEATURE ONLY
 */
const wlCode = localStorage.getItem('whitelabel_code');
axios.defaults.headers.wl_code = (wlCode && localStorage.getItem('signinWLAdmin')) ? wlCode : 2;

library.add([faPlaneDeparture, faPlaneArrival, faAngleUp, faAngleDown]);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.filter('formatDate', (value) => {
  if (value) {
    return moment
      .utc(value)
      .local()
      .format('DD-MM-YYYY');
  }
  return 'NA';
});

Vue.filter('formatDateToString', (value) => {
  if (value) {
    return moment
      .utc(value)
      .local()
      .format('D-MMMM-Y');
  }
  return 'NA';
});

Vue.filter('UpperCase', (value) => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  return 'NA';
});

Vue.filter('formatDateTime', (value) => {
  const format1 = 'YYYY-MM-DD HH:mm:ss';

  if (value) {
    return moment
      .utc(value)
      .local()
      .format(format1);
  }
  return 'NA';
});

// get WL Config by wl_code
store.dispatch('auth/getConfig');

// get localstorage token
store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});

// get currency by wl_code
store.dispatch('auth/setCurrency');
